import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.cookieBar = this.element.querySelector('.cookie-bar');
        this.cookiePreferences = this.element.querySelector('.cookie-preferences');
        this.cookiePreferencesOpen = this.element.querySelector('#openCookiePreferences');

        this.cookiePreferencesOpen.addEventListener('click', () => this.openCookiePreferences());

        for (const cookieSubmit of this.element.querySelectorAll('.cookie-submit')) {
            cookieSubmit.addEventListener('click', () => this.saveCookies(cookieSubmit));
        }
    }

    saveCookies(cookieSubmit) {
        const required = this.cookiePreferences.querySelector('input[name=cookie_required]').checked;
        const functional = this.cookiePreferences.querySelector('input[name=cookie_functional]').checked;
        const analytics = this.cookiePreferences.querySelector('input[name=cookie_analytics]').checked;
        const acceptAll = cookieSubmit.classList.contains('accept-all');
        const rejectAll = cookieSubmit.classList.contains('reject-all');

        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toGMTString()}`;

        document.cookie = `consents_essentials=1;${expires};path=/`;
        let consentAnalytics = false;

        if (acceptAll) {
            document.cookie = `consents_required=1;${expires};path=/`;
            document.cookie = `consents_functional=1;${expires};path=/`;
            document.cookie = `consents_analytics=1;${expires};path=/`;

            consentAnalytics = true;
            this.cookieBar.classList.add('d-none');
        } else if (rejectAll) {
            document.cookie = `consents_required=0;${expires};path=/`;
            document.cookie = `consents_functional=0;${expires};path=/`;
            document.cookie = `consents_analytics=0;${expires};path=/`;

            this.animateOut(this.cookiePreferences, () => {
            }, () => {
                this.cookiePreferences.classList.add('d-none');
            });
        } else {
            document.cookie = `consents_required=${required ? '1' : '0'};${expires};path=/`;
            document.cookie = `consents_functional=${functional ? '1' : '0'};${expires};path=/`;
            document.cookie = `consents_analytics=${analytics ? '1' : '0'};${expires};path=/`;
            consentAnalytics = analytics;

            this.animateOut(this.cookiePreferences, () => {
            }, () => {
                document.body.classList.remove('modal--open');
                this.cookiePreferences.classList.add('d-none');
            });
        }

        if (!consentAnalytics) {
            this.removeAnalyticsCookies();
        }

        window.location.reload();
    }

    openCookiePreferences() {
        this.cookieBar.classList.add('d-none');

        this.animateIn(this.cookiePreferences, () => {
            this.cookiePreferences.classList.add('d-block');
            document.body.classList.add('modal--open');
        }, () => {
        });
    }

    animateIn(target, before, callback) {
        let op = 0.1;
        target.style.opacity = op;
        before();
        const timer = setInterval(function () {
            if (op >= 1) {
                clearInterval(timer);
                callback();
            }
            target.style.opacity = op;
            target.style.filter = `alpha(opacity=${op * 100})`;
            op += op * 0.1;
        }, 5);
    }

    animateOut(target, before, callback) {
        let op = 1;
        target.style.opacity = op;
        before();
        const timer = setInterval(function () {
            if (op <= 0.1) {
                clearInterval(timer);
                callback();
            }
            target.style.opacity = op;
            target.style.filter = `alpha(opacity=${op * 100})`;
            op -= op * 0.1;
        }, 5);
    }

    removeAnalyticsCookies() {
        // google
        document.cookie = '_ga=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_gid=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_ga_WPHLW5QB3J=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_dc_gtm_UA-57675352-1=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        // hotjar
        document.cookie = '_hjSessionUser_3928593=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_hjSession_3928593=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_hjIncludedInSessionSample_3928593=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_hjFirstSeen=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = '_hjAbsoluteSessionInProgress=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        // fb
        document.cookie = '_fbp=; Path=/; Domain=.lemongym.lv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}
