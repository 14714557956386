import { Controller } from '@hotwired/stimulus';
import Leaflet from 'leaflet';
import markerUrl from '../../images/icons/map-marker.svg';
import Swiper, {Navigation, Pagination} from 'swiper';

let map;
let mapMarkers = [];
let swiper;

export default class extends Controller {
    static get targets () {
        return ['map', 'slider', 'club'];
    }

    mapTargetConnected(element) {
        const markers = JSON.parse(element.dataset.markers) || [];

        const initialPosition = markers.filter(marker => {
            return marker.lat && marker.lng;
        })[0];

         map = Leaflet.map(element, {
            center: [initialPosition.lat, initialPosition.lng],
            zoom: 15,
            scrollWheelZoom: false,
            zoomControl: false
        });


        Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'mapbox/dark-v10',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: 'pk.eyJ1IjoibGVtb25neW1sdiIsImEiOiJjbHVpZXJiZXgwM2J6MnBsbXBlcWNqcXV4In0.qyoe9MlpH_NmhePBcFS2DA',
        }).addTo(map);

        markers.forEach((marker, index) => {
            if (marker.lat && marker.lng) {
                mapMarkers[index] = Leaflet.marker([marker.lat, marker.lng], {
                    icon: new (Leaflet.Icon.extend({
                        options: {
                            iconSize: [44, 44],
                            iconAnchor: [16, 41],
                        },
                    }))({ iconUrl: markerUrl }),
                }).addTo(map).bindPopup(marker.popup, {className: 'club-popup', maxWidth: 320, offset: [7, -16]});

                if (index === 0) {
                    mapMarkers[index].openPopup();
                }
            }
        });
    }

    sliderTargetConnected(element) {
        Swiper.use([Navigation, Pagination]);
        swiper = new Swiper(element, {
            spaceBetween: 12,
            slidesPerView: 1.5,
            pagination: {
                el: element.querySelector('.swiper-pagination'),
                clickable: true
            },
            navigation: {
                nextEl: element.querySelector('.swiper-navigation-next'),
                prevEl: element.querySelector('.swiper-navigation-prev'),
            },
            breakpoints: {
                991: {
                    spaceBetween: 16,
                    slidesPerView: 4.04,
                }
            }
        });

        swiper.on('slideChange', (sw) => {
            element.querySelectorAll('.swiper-numeration__current').forEach(item => {
                item.innerHTML = sw.realIndex +1;
            });
        });
    }

    clubTargetConnected(element) {
        element.addEventListener('click', () => {
            this.clubTargets.forEach(clubTarget => {
                clubTarget.classList.remove('active');
            });

            element.classList.add('active');
            if (mapMarkers[element.dataset.index]) {
                map.flyTo(mapMarkers[element.dataset.index].getLatLng());
                mapMarkers[element.dataset.index].openPopup();
                swiper.slideTo(element.dataset.index);
            }
        });
    }
}
